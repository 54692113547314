import { h } from "@stencil/core";
import { CsiBrandFooter } from "./csi-brand-footer";

export default function(cmp: CsiBrandFooter): object {
  const footer =
    <footer class="footer" id="footer">
      <div class="footer-container">
        <div class="footer-top">
          <div class="logo--footer">
            <a href="https://www.uiowa.edu" target="blank">
              <svg
                part="svg-elem"
                xmlns="http://www.w3.org/2000/svg"
                class="logo-icon"
                aria-labelledby="University of Iowa"
                role="img"
                viewBox="0 0 311.6 90.2"
              >
                <path class="st0" d="M40 18.8h-7.3v52.4H40v19H0v-19h7.3V18.8H0V0h40V18.8z" />
                <path class="st0" d="M93.8 90.2h-29c-10.5 0-17.4-6.9-17.4-18.2V18.2C47.4 7 54.4 0 64.8 0h29c10.5 0 17.4 7 17.4 18.2V72C111.2 83.2 104.2 90.2 93.8 90.2zM85.6 71.2V18.8H73v52.4H85.6z" />
                <path class="st0" d="M122.6 18.8h-6.4V0h38v18.9H147l6.5 43.4L167 0h19.2l14.4 62.3 5.2-43.4h-6.6V0h37.5v18.9h-6.2l-11.3 71.4h-30.6l-11.8-53.2 -12.1 53.1h-29.4L122.6 18.8z" />
                <path class="st0" d="M230.1 71.2h6.9L250.7 0h41l13.5 71.2h6.4v19H281l-2.9-22h-15.2l-2.7 22h-30L230.1 71.2 230.1 71.2zM276.5 51.7l-5.8-36.4 -6 36.4H276.5z" />
                <title id="University of Iowa footer logo">University of Iowa</title>
              </svg>
            </a>
          </div>
          <div class="site-titles">
            {
              (cmp.sitename !== '') &&
              <h2 class="site-name">
              <a class="site-name-link" href={cmp.sitelink}>
                { cmp.sitename }
              </a>
            </h2>
            }
            {
              (cmp.siteparent !== '') &&
            <p class="parent-site-name">
              <a href={cmp.parentlink}>
              { cmp.siteparent }</a>
            </p>
            }
          </div>
          <div class="contact-info">
            <p>
              { cmp.addressline }<br />
              <a href="tel:319-335-1023">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" aria-labelledby="phone number" height="48" viewBox="0 -960 960 960" width="48"><path d="M795-120q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19 13-32t32-13h140q14 0 24 10t14 25l27 126q2 14-.5 25.5T359-634L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23-15t26-2l119 26q15 4 25 16t10 27v135q0 19-13 32t-32 13Z"/></svg>
                319-335-1023
              </a><br />
            </p>
          </div>
        </div>

        <div class="footer__links footer__links--icon">
          <ul class="menu">
            {
              (cmp.facebooklink !== '') &&
              <li class="menu-item">
                <a href={ cmp.facebooklink }>
                  <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><style></style><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg>
                  <span class="link-text">Facebook</span></a>
              </li>
            }
            {
              (cmp.twitterlink !== '') &&
              <li class="menu-item">
                <a href={ cmp.twitterlink }>
                  <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><style></style><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/></svg></a>
              </li>
            }
            {
              (cmp.instagramlink !== '') &&
              <li class="menu-item">
                <a href={ cmp.instagramlink }>
                  <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><style></style><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                  <span class="link-text">Instagram</span></a>
              </li>
            }
          </ul>
        </div>

        <div class="socket">
          <ul class="socket__menu">
            <li>&copy; { cmp.year } The University of Iowa | Developed by <a href="https://csi.its.uiowa.edu/" target="_blank" title="Custom Solutions and Integrations">Custom Solutions &amp; Integrations</a>
            </li>
            <li>
              <a href="https://uiowa.edu/privacy">Privacy Notice</a>
            </li>
            <li>
              <a href="https://opsmanual.uiowa.edu/community-policies/nondiscrimination-statement">Nondiscrimination Statement</a>
            </li>
            <li>
              <a href="https://uiowa.edu/accessibility">Accessibility</a>
            </li>
            <li>
              <a href="https://nativeamericancouncil.org.uiowa.edu">UI Indigenous Land Acknowledgement</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>;
    return footer;
}
