@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

:host * {
  font-size: 16px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  touch-action: manipulation;
  --ui-brand-gold: #ffcd00;
  --ui-brand-black: #151515;
  --light: #ffffff;
  --dark: #000000;
  --gray: #ccc;
}

.footer {
  background-color: var(--ui-brand-black);
  -webkit-font-smoothing: antialiased;  
  padding-top: 1.875rem;
  padding-right: 0;
  padding-bottom: 1.875rem;
  padding-left: 0;
  .footer-container {
    max-width: 81.875rem;
    margin: 0 1.25rem;
  }
  @media (min-width: 84.375em) {
    .footer-container {
      max-width: 81.875em;
      margin: 0 auto; } }
  @media (min-width: 84.375em) {
    .footer-container {
      padding: 0; } }
}

.footer-top .logo--footer svg.logo-icon  {
  fill: var(--ui-brand-gold);
  stroke: var(--ui-brand-gold);
  margin-bottom: .625rem;  
}
@media (min-width: 1400.01px) {
  .footer-top .logo--footer svg.logo-icon {
    /* @include setWidth(10rem); */
    min-width: 10rem;
    width: 10rem;
    max-width: 10rem;
  }
}
@media (max-width: 1400px) {
  .footer-top .logo--footer svg.logo-icon {
      /* @include setWidth(7.5rem); */
      min-width: 7.5rem;
      width: 7.5rem;
      max-width: 7.5rem;
  }
}

.footer a {
  text-decoration: none;
  color: var(--ui-brand-gold);
  font-size: 1.2rem;
  &:hover {
    text-decoration: underline !important;
  }
}

.footer p {
  color: var(--gray);
  line-height: 1.5;
  font-weight: 100;
  font-size: 1.2rem;
}
.footer .contact-info p {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.footer .icon {
  vertical-align: middle;
  margin-right: .5rem;
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--gray);
}

.footer .site-name {
  color: var(--light) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  letter-spacing: 0 !important;
  .site-name-link {
    color:  var(--light) !important;
    font-size: 1.5rem !important;
    font-family: 'Zilla Slab', serif !important;
  }
}

.footer .parent-site-name {
  color: var(--gray) !important;
  font-size: 1.2rem;
  font-weight: 300;
  display: block;
  padding: 0;
  font-style: normal !important;
  line-height: 1.5;
  margin: 0;
  a {
    text-decoration: none;
    color: var(--gray) !important;
  }
}

/* Social Media Links */
.footer__links .menu, 
.footer__links .menu ul li, 
.footer__links--icon ul,
.footer__links--icon li, 
.socket__menu {
  list-style-type: none;
  padding: 0;
  margin: 0; 
}
.footer__links .menu > li, 
.footer__links .menu ul li > li, 
.footer__links--icon ul > li, 
.footer__links--icon li > li, 
.socket__menu > li {
  display: inline-block;
  list-style-type: none;
  margin: 0; 
}
.footer__links {
  margin-bottom: 1.875rem;
}
.footer__links--icon .menu {
  flex-wrap: wrap;
  display: flex;
  line-height: 2; 
}
@media (min-width: 980px) {
  .footer__links--icon .menu {
    line-height: 1 !important; 
  } 
}
.footer__links--icon svg {
  color: #fff;
  font-size: 1.6rem !important;
  margin-right: 1.05rem !important; 
}
.footer__links--icon .link-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); 
}

/* University Notice Links */
.socket__menu {
  display: flex;
  flex-wrap: wrap; 
}
  .socket__menu li {
    color: white;
    font-size: 0.8rem !important;
    border-right: 1px solid #5d5656;
    margin-bottom: 0.325rem;
    padding-top: 0;
    padding-right: 0.625rem;
    padding-left: 0.625rem; 
  }
    .socket__menu li:first-child {
      flex-basis: 100%;
      border-right: none; 
    }
    .socket__menu li:nth-child(2) {
      padding-left: 0; 
    }
    .socket__menu li:last-child {
      border-right: none;
      padding-right: 0; 
    }
    .socket__menu li:first-child {
      padding-left: 0; 
    }
    .socket__menu li a {
      text-decoration: none;
      font-size: 0.8rem !important;
      color: #FFCD00; 
    }
    .socket__menu li a:hover, .socket__menu li a:focus {
      text-decoration: underline; 
    }