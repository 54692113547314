import { Component, Prop, Watch } from '@stencil/core';
import footer from './csi-brand-footer.html';

@Component({
  tag: 'csi-brand-footer',
  styleUrls: ['./css/csi-brand-footer.css'],
  assetsDirs: ['assets'],
  shadow: true,
})
export class CsiBrandFooter {
  /** The site name   */
  @Prop({mutable: true, reflect: true}) sitename!: string;
  @Watch('sitename')
  validateName(newValue: string) {
    // don't allow `sitename` to be the empty string
    const isBlank = typeof newValue !=='string' || newValue === '';
    if (isBlank) {
      throw new Error('sitename is a required property and cannont be empty.')
    }
    // don't allow `sitename` to be a string with a length of 1
    const has2chars = typeof newValue === 'string' && newValue.length >= 2;
    if (!has2chars) {
      throw new Error('sitename must have a length of more than 1 character')
    }
  }

  /** The link when clicking the site name  */
  @Prop({mutable:true, reflect:true}) sitelink: string;

  /** The parent if it has one  */
  @Prop() siteparent: string;

  /** The link when clicking the parent name  */
  @Prop() parentlink: string;

  /** The link when clicking the site name  */
  @Prop() addressline: string;

  /** The link when clicking the Facebook  */
  @Prop() facebooklink: string;

  /** The link when clicking the instgram  */
  @Prop() instagramlink: string;

  /** The link when clicking the twitter  */
  @Prop() twitterlink: string;



  componentDidLoad() {
    console.log('first load??');
    this.validateName( this.sitename);
  }

  year = new Date().getFullYear();

  render() {
    return footer(this);
  }
}
